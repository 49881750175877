/** @jsxImportSource @emotion/react */
import React, { useLayoutEffect, useState } from 'react';
import { useAuth, useAuthActions, useLoginWithRedirect } from '@frontegg/react';
import { clarityInitialize, pendoInitialize } from '../external-tools';
import { setCurrentTenant, TTenantDetails, getTenant, getCurrentTenant } from '../routes/ManageTenants/tenants';
import { setFronteggJWTCookie, logout, addLoginState, removeLoginState, setUserTokenLocalStorage } from '../utils/fronteggAuth';
import { PSLoadingScreen } from '../ui-kit';
import { addInterceptor } from '../utils/fetchInterceptor';

type IProps = {
    children: React.ReactNode;
};

const RootAuthWrapper: React.FC<IProps> = (props) => {
    const { switchTenant } = useAuthActions();
    const { children } = props;
    const { isAuthenticated, isLoading: isUserLoginLoading, user } = useAuth();
    const loginWithRedirect = useLoginWithRedirect();
    const [loggedIn, setLoggedIn] = useState<string | null>(localStorage.getItem("loggedIn"));

    useLayoutEffect((): void => {
        (async () => {
            if (!user || isUserLoginLoading || !isAuthenticated) return;
            setFronteggJWTCookie(user.accessToken);
            setUserTokenLocalStorage(user.accessToken);

            addInterceptor({
                request: async (url, config) => {
                    if (!url.startsWith('/api/')) {
                        return config;
                    }

                    return {
                        ...config,
                        headers: {
                            ...config.headers,
                            'Authorization': `Bearer ${user?.accessToken}`,
                        }
                    };
                },
                response: async (response) => {
                    if (!response.ok) {
                        if (response.status === 401) {
                            logout();
                        }
                    }
                    return response;
                },
                responseError: async (error) => {
                    throw error;
                }
            });

            const psCookieTenant = getCurrentTenant();
            const userTenant: TTenantDetails | null | undefined = await getTenant(user);
            if (!userTenant) {
                logout();
                return;
            }

            if (psCookieTenant?.id !== userTenant.id) {
                setCurrentTenant(userTenant);
                switchTenant({ tenantId: userTenant.id });
                removeLoginState()
            }


            if (loggedIn === null) {
                await fetch('/api/access/register-login');
                addLoginState();
                setLoggedIn("");
            }

            if (process.env.NODE_ENV !== 'development') {
                pendoInitialize(user);
                clarityInitialize();
            }
        })();
    }, [user, isAuthenticated, isUserLoginLoading]);

    if (isUserLoginLoading) {

        return <PSLoadingScreen />;
    }

    if (!isAuthenticated) {
        loginWithRedirect()
        return <PSLoadingScreen />;
    }

    return (
        <React.Fragment>
            {children}
        </React.Fragment>
    )
}

export default RootAuthWrapper;