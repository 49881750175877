import { createTheme } from '@uiw/codemirror-themes';
import { tags as t } from '@lezer/highlight';

export const codeMirrorTheme = createTheme({
    theme: 'light',
    settings: {
        background: 'var(--color-black-5)',
        foreground: 'var(--color-black-80)',
        caret: 'var(--color-purple-70)',
        selectionMatch: 'var(--color-black-40)',
        selection: 'var(--color-black-30)',
        gutterBackground: 'var(--color-black-5)',
        gutterForeground: 'var(--color-black-60)',
        lineHighlight: 'rgb(from var(--color-black-20) r g b / .4)',
        gutterActiveForeground: 'var(--color-black-80)',
        fontSize: '16px',
    },
    styles: [
        { tag: t.comment, color: 'var(--color-black-60)' },
        { tag: t.variableName, color: 'var(--color-purple-70)' },
        { tag: [t.string, t.special(t.brace)], color: 'var(--color-aqua-60)' },
        { tag: t.number, color: 'var(--color-black-80)' },
        { tag: t.bool, color: 'var(--color-black-80)' },
        { tag: t.null, color: 'var(--color-black-80)' },
        { tag: t.keyword, color: 'var(--color-black-80)' },
        { tag: t.operator, color: 'var(--color-black-80)' },
        { tag: t.className, color: 'var(--color-black-80)' },
        { tag: t.typeName, color: 'var(--color-purple-70)' },
        { tag: t.tagName, color: 'var(--color-blue-50)' },
        { tag: t.attributeName, color: 'var(--color-black-80)' }
    ],
});