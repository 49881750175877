/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from 'react';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { Checkbox, IconButton, Radio, RadioGroup, TextField } from '@mui/material';
import { langs } from '@uiw/codemirror-extensions-langs';
import { useConnectorContext } from '../../../../contexts';
import { graphql } from '../../../../gql';
import { useGraphQL, useGraphQLMutation } from '../../../../hooks';
import { Icon, PSBox, PSButton, PSCodeMirror, PSCopyText, PSDialog, PSFormControlLabel, Text } from '../../../../ui-kit';
import { HomegrownApplicationsReverseProxyStyle } from './HomegrownApplicationsReverseProxy.css';
import { ProxyMapping } from '../../../../gql/generated/graphql';
import { codeMirrorTheme } from '../../../../styles';
import { ai21Icon, anthropicIcon, azureOpenaiIcon, bedrockIcon, cohereIcon, fireworksIcon, geminiIcon, groqIcon, huggingfaceIcon, langchainIcon, mistralIcon, nvidiaIcon, ollamaIcon, openaiIcon, openrouterIcon, perplexityIcon, replicateIcon, togetherIcon, vertexaiIcon, xaiIcon } from '../../../../assets/images/providers';
import { css } from '@emotion/react';
import ProviderButton from './ProviderButton';
import { getCodeExample, TCodeLanguages } from './providersCodeExamples';
import { HomegrownApplicationsSDKStyle } from './HomegrownApplicationsSDK.css';
import { censorStringEnd } from '../../../../utils';

type FormValues = {
    proxyMappings: Array<Omit<ProxyMapping, 'application'>>;
}

export type TProvidersKeys = 'azureOpenai' | 'openai' | 'bedrock' | 'vertexai' | 'langchain' | 'ollama' | 'anthropic' | 'huggingface' | 'perplexity' | 'gemini' | 'cohere' | 'groq' | 'xai' | 'mistral' | 'nvidia' | 'ai21' | 'fireworks' | 'openrouter' | 'replicate' | 'together';

const providersObj = [
    { key: 'azureOpenai', label: 'Azure OpenAI', icon: azureOpenaiIcon },
    { key: 'openai', label: 'OpenAI', icon: openaiIcon },
    { key: 'bedrock', label: 'AWS Bedrock', icon: bedrockIcon },
    { key: 'vertexai', label: 'Google Vertex AI', icon: vertexaiIcon },
    { key: 'langchain', label: 'Langchain', icon: langchainIcon },
    { key: 'ollama', label: 'Ollama', icon: ollamaIcon },
    { key: 'anthropic', label: 'Anthropic', icon: anthropicIcon },
    { key: 'huggingface', label: 'Huggingface', icon: huggingfaceIcon },
    { key: 'perplexity', label: 'Perplexity', icon: perplexityIcon },
    { key: 'gemini', label: 'Google AI Studio', icon: geminiIcon },
    { key: 'cohere', label: 'Cohere', icon: cohereIcon },
    { key: 'groq', label: 'Groq', icon: groqIcon },
    { key: 'xai', label: 'X.ai', icon: xaiIcon },
    { key: 'mistral', label: 'Mistral', icon: mistralIcon },
    { key: 'nvidia', label: 'Nvidia', icon: nvidiaIcon },
    { key: 'ai21', label: 'AI21', icon: ai21Icon },
    { key: 'fireworks', label: 'Fireworks', icon: fireworksIcon },
    { key: 'openrouter', label: 'Openrouter', icon: openrouterIcon },
    { key: 'replicate', label: 'Replicate', icon: replicateIcon },
    { key: 'together', label: 'Together.ai', icon: togetherIcon },
]satisfies { key: TProvidersKeys; label: string; icon: React.ComponentType<React.SVGProps<SVGSVGElement>> }[];

const getProxyMappingsQuery = graphql(`
    query ProxyMappings {
        proxyMappings {
            forwardDomain
            id
            vendorKey
            application {
                id
            }
        }
    }
`);

const deleteProxyMappingQuery = graphql(`
    mutation DeleteProxyMapping($id: ID!) {
        deleteProxyMapping(id: $id) {
            id
        }
    }
`);

const upsertProxyMappingQuery = graphql(`
    mutation UpsertProxyMapping($input: [UpsertProxyMappingInput!]!) {
        upsertProxyMapping(input: $input) {
            id
        }
    }
`);

const HomegrownApplicationsReverseProxy: React.FC = () => {
    const { connector } = useConnectorContext();
    const connectorIdCensored = censorStringEnd(connector.id, 12);

    const [showApiKey, setShowApiKey] = useState(false);
    const apiKey = showApiKey ? connector.id : connectorIdCensored;

    const [showAllProviders, setShowAllProviders] = useState(false);
    const [selectedProvider, setSelectedProvider] = useState<TProvidersKeys>('azureOpenai');
    const [selectedCodeLanguage, setSelectedCodeLanguage] = useState<TCodeLanguages>('python');

    const visibleProviders = showAllProviders ? providersObj : providersObj.slice(0, 5);
    const remainingCount = providersObj.length - 5;

    const onProviderChange = (provider: TProvidersKeys) => {
        setSelectedProvider(provider);
    }

    const onCodeLanguageChange = (language: TCodeLanguages) => {
        setSelectedCodeLanguage(language);
    }

    const [visibleApiKeys, setVisibleApiKeys] = useState<Record<string, boolean>>({});

    const toggleApiKeyVisibility = (index: string) => {
        setVisibleApiKeys(prev => ({
            ...prev,
            [index]: !prev[index]
        }));
    };


    const { control, handleSubmit, formState, reset, watch } = useForm<FormValues>({
        mode: 'onChange',
        defaultValues: {
            proxyMappings: []
        }
    });

    const { fields: proxyMappings, append } = useFieldArray({
        control,
        keyName: 'uuid',
        name: 'proxyMappings'
    });

    const [proxyMappingToDeleteId, setProxyMappingToDeleteId] = useState<string | null>(null);

    const upsertProxyMapping = useGraphQLMutation({
        document: upsertProxyMappingQuery,
    });

    const deleteProxyMapping = useGraphQLMutation({
        document: deleteProxyMappingQuery,
    });

    const proxyMapping = useGraphQL({
        document: getProxyMappingsQuery,
        onSuccess: (data) => {
            reset({ proxyMappings: data.proxyMappings.filter((x) => x.application.id === connector.id) });
        }
    });

    const addNewRow = () => {
        append({ forwardDomain: '', vendorKey: '', id: 'NEW_' + Math.random().toString(36).substring(7) });
    };

    const handleDelete = (id: string) => {
        if (id.startsWith('NEW_')) {
            reset({ proxyMappings: proxyMappings.filter((x) => x.id !== id) });
            return;
        }

        setProxyMappingToDeleteId(id);
    };

    const deleteProxyMappingRow = async (id: string) => {
        await deleteProxyMapping.mutateAsync({ id });
        proxyMapping.refetch();
    }

    const saveProxyMappings = async (data: FormValues) => {
        const input = data.proxyMappings.map(({ forwardDomain, vendorKey, id }) => ({
            applicationId: connector.id,
            forwardDomain,
            vendorKey,
            id
        }));
        await upsertProxyMapping.mutateAsync({ input });
        proxyMapping.refetch();
    };

    const cancelEdit = () => {
        reset();
    };

    useEffect(() => {
        proxyMapping.refetch();
    }, [connector.id]);

    const watchedFields = watch('proxyMappings');
    const isDirty = formState.isDirty;
    const hasErrors = watchedFields.some(mapping => !mapping.forwardDomain || !mapping.vendorKey);

    return (
        <PSBox padding={30}>
            <Text css={HomegrownApplicationsReverseProxyStyle.title} variant='header2'>LLM Gateway</Text>

            <div css={HomegrownApplicationsSDKStyle.apiKey}>
                <PSFormControlLabel label='Display API Key:' control={
                    <Checkbox
                        checked={showApiKey}
                        onChange={(e) => setShowApiKey(e.target.checked)}
                        color='primary'
                    />
                } />
                <PSCopyText label={apiKey} text={connector.id} />
            </div>

            <div css={css`display: flex; flex-direction: column; gap: 40px; border-bottom: 1px solid var(--color-black-30); padding-bottom: 20px; margin-bottom: 20px;`}>
                <div css={css`display: flex; flex-direction: column; gap: 20px;`}>
                    <Text variant='bold'>Select LLM Provider</Text>
                    <div css={css`display: flex; flex-wrap: wrap; gap: 15px; max-width: 1015px;`}>
                        {visibleProviders.map((provider) => (
                            <ProviderButton key={provider.key} selected={selectedProvider === provider.key} onClick={() => onProviderChange(provider.key)}>
                                <provider.icon css={css`min-width: 20px; min-height: 20px;`} />
                                <Text>{provider.label}</Text>
                            </ProviderButton>
                        ))}

                        <ProviderButton onClick={() => setShowAllProviders(!showAllProviders)}>
                            <Text color='purple-80' textCss={css`text-wrap: nowrap;`}>
                                {showAllProviders ? 'Show less' : `Show more (${remainingCount})`}
                            </Text>
                        </ProviderButton>
                    </div>
                </div>


                <div css={css`display: flex; flex-direction: column; gap: 15px;`}>
                    <Text variant='bold'>Use the following code snippet to deploy to Prompt Security LLM Gateway:</Text>

                    <RadioGroup row defaultValue='python' onChange={(_, value) => onCodeLanguageChange(value as TCodeLanguages)}>
                        <PSFormControlLabel value='python' label='Python' control={<Radio size='small' />} />
                        <PSFormControlLabel value='typescript' label='Typescript' control={<Radio size='small' />} />
                    </RadioGroup>

                    <PSCodeMirror
                        id='code-mirror'
                        readOnly
                        theme={codeMirrorTheme}
                        value={getCodeExample(apiKey)[selectedProvider as TProvidersKeys][selectedCodeLanguage]}
                        extensions={[langs[selectedCodeLanguage]()]}
                        copyButton
                    />
                </div>
            </div>

            <div>
                <Text variant='bold'>Can’t add HTTP headers?</Text>
                <Text>Use Proxy mapping</Text>
                {proxyMappings.length > 0 ? (
                    <div>
                        <div css={HomegrownApplicationsReverseProxyStyle.proxyMappingContainer}>
                            {proxyMappings.map((proxy, index) => (
                                <div key={proxy.id} css={HomegrownApplicationsReverseProxyStyle.proxyMappingRow}>
                                    <Controller
                                        name={`proxyMappings.${index}.forwardDomain`}
                                        control={control}
                                        defaultValue={proxy.forwardDomain}
                                        rules={{
                                            required: true,
                                            pattern: {
                                                value: /^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z]{2,6}$/,
                                                message: 'Invalid domain'
                                            }
                                        }}
                                        render={({ field, fieldState }) => (
                                            <TextField
                                                {...field}
                                                size='small'
                                                label='LLM Domain'
                                                fullWidth
                                                error={!!fieldState.error}
                                                helperText={fieldState.error?.message}
                                            />
                                        )}
                                    />
                                    <Icon iconName='PSArrowRightIcon' color='black-40' />
                                    <Controller
                                        name={`proxyMappings.${index}.vendorKey`}
                                        control={control}
                                        defaultValue={proxy.vendorKey}
                                        rules={{ required: true }}
                                        render={({ field, fieldState }) => (
                                            <TextField
                                                {...field}
                                                size='small'
                                                label='LLM API Key'
                                                fullWidth
                                                error={!!fieldState.error}
                                                type={visibleApiKeys[index] ? 'text' : 'password'}
                                                InputProps={{
                                                    endAdornment: <IconButton onClick={() => toggleApiKeyVisibility(index.toString())}><Icon iconSize='small' iconName={visibleApiKeys[index] ? 'PSHideIcon' : 'PSShowIcon'} color='black-70' /></IconButton>
                                                }}
                                            />
                                        )}
                                    />
                                    <IconButton onClick={() => handleDelete(proxy.id)}><Icon iconName='PSDeleteIcon' color='black-70' /></IconButton>
                                </div>
                            ))}
                        </div>

                        <div css={HomegrownApplicationsReverseProxyStyle.actionsContainer}>
                            <PSButton variant='flat' iconName='PSAddIcon' placement='start' isLoading={upsertProxyMapping.isPending} onClick={addNewRow}>Add Proxy Mapping</PSButton>
                            <PSButton css={css`margin-left: auto;`} variant='outlined' isLoading={upsertProxyMapping.isPending} onClick={cancelEdit} disabled={!isDirty}>Cancel</PSButton>
                            <PSButton variant='filled' isLoading={upsertProxyMapping.isPending} onClick={handleSubmit(saveProxyMappings)} disabled={!isDirty || hasErrors || Object.keys(formState.errors).length > 0}>Save</PSButton>
                        </div>
                    </div>
                ) : <PSButton placement='start' variant='outlined' iconName='PSAddIcon' onClick={addNewRow} css={HomegrownApplicationsReverseProxyStyle.noProxyMapping}>Add Proxy Mapping</PSButton>}
            </div>

            <PSDialog
                title='Delete Proxy Mapping'
                open={!!proxyMappingToDeleteId}
                onClose={() => setProxyMappingToDeleteId(null)}
                action={() => deleteProxyMappingRow(proxyMappingToDeleteId!)}
                actionButtonText='Delete'
                actionButtonVariantType='critical'
            >
                <Text>Are you sure you want to delete this proxy mapping?</Text>
            </PSDialog>
        </PSBox >
    );
};

export default HomegrownApplicationsReverseProxy;