import { TProvidersKeys } from "./HomegrownApplicationsReverseProxy";

type TCodeExample = Record<
  TProvidersKeys,
  { python: string; typescript: string }
>;

export type TCodeLanguages = keyof TCodeExample[TProvidersKeys];

export const getCodeExample = (appId: string): TCodeExample => {
  const domain = window.location.hostname;
  return {
    azureOpenai: {
      python: `import os
from openai import AzureOpenAI

azure_openai_instance = "ps-azure-openai"
client = AzureOpenAI(
  api_version="2024-02-01",
  azure_endpoint="https://${domain}",
  default_headers={
      "ps-app-id": "${appId}",
      "forward-domain": f"{azure_openai_instance}.openai.azure.com",
  },
)

response = client.chat.completions.create(
  model="gpt-35-turbo",
  messages=[
      {"role": "user", "content": "Hello"},
  ],
)
print(response.choices[0].message.content.strip())`,
      typescript: `import 'dotenv/config';
import { AzureOpenAI } from "openai";

const azure_openai_instance = "ps-azure-openai";
const client = new AzureOpenAI({
apiVersion: "2024-02-01",
endpoint: 'https://${domain}',
defaultHeaders: {
  "ps-app-id": "${appId}",
  "forward-domain": \`\${azure_openai_instance}.openai.azure.com\`,
}
});

const response = await client.chat.completions.create({
model: "gpt-35-turbo",
messages: [{ role: "user", content: "Hello" }]
});
console.log(response.choices[0].message.content.trim());`,
    },

    openai: {
      python: `import os
from openai import OpenAI

client = OpenAI(
  base_url="https://${domain}/v1/",
  default_headers={"ps-app-id": "${appId}"},
)

response = client.chat.completions.create(
  model="gpt-3.5-turbo",
  messages=[
      {"role": "user", "content": "Hello"},
  ],
)
print(response.choices[0].message.content.strip())
`,
      typescript: `import OpenAI from 'openai';

const client = new OpenAI({
baseURL: 'https://${domain}/v1/',
defaultHeaders: { "ps-app-id": "${appId}" }
});

const response = await client.chat.completions.create({
model: "gpt-3.5-turbo",
messages: [{ role: "user", content: "Hello" }]
});
console.log(response.choices[0].message.content.trim());`,
    },

    bedrock: {
      python: `import os
import json
import boto3

prompt_security_domain = "${domain}"
aws_region = "us-east-1"

bedrock = boto3.client(
  "bedrock-runtime",
  region_name=aws_region,
  endpoint_url=f"https://{prompt_security_domain}",
)


def modify_host_header(request, **kwargs):
  request.headers["host"] = f"bedrock-runtime.{aws_region}.amazonaws.com"
  request.headers["forward-domain"] = request.headers["host"]
  request.headers["ps-app-id"] = "${appId}"


def modify_headers(request, **kwargs):
  request.headers["host"] = prompt_security_domain


bedrock.meta.events.register("before-sign.*", modify_host_header)
bedrock.meta.events.register("before-send.*", modify_headers)

body = json.dumps(
  {
      "anthropic_version": "bedrock-2023-05-31",
      "max_tokens": 1024,
      "messages": [{
          "role": "user",
          "content": [{"type": "text", "text": "Hello"}]
      }]
  }
)
response = bedrock.invoke_model(
  body=body, modelId="anthropic.claude-3-sonnet-20240229-v1:0"
)
response_body = json.loads(response.get("body").read())
print(response_body["content"][0]["text"])`,
      typescript: `import { BedrockRuntimeClient, InvokeModelCommand } from "@aws-sdk/client-bedrock-runtime";

const prompt_security_domain = "${domain}";
const aws_region = "us-east-1";

const bedrock = new BedrockRuntimeClient({
region: aws_region,
endpoint: \`https://\${prompt_security_domain}\`
});

bedrock.middlewareStack.add(
  (next) => async (args) => {
      args.request.headers["host"] = \`bedrock-runtime.\${aws_region}.amazonaws.com\`;
      return next(args);
  },
  { step: "build" }
);

bedrock.middlewareStack.add(
  (next) => async (args) => {
      args.request.headers["host"] = prompt_security_domain;
      args.request.headers["ps-app-id"] = "${appId}";
      args.request.headers["forward-domain"] = \`bedrock-runtime.\${aws_region}.amazonaws.com\`;
      return next(args);
  },
  { step: "finalizeRequest" }
);

const body = {
anthropic_version: "bedrock-2023-05-31",
messages: [{ role: "user", content: [{ type: "text", text: "Hello" }] }],
max_tokens: 1024
};

const command = new InvokeModelCommand({
modelId: "anthropic.claude-3-sonnet-20240229-v1:0",
body: JSON.stringify(body),
});

try {
const response = await bedrock.send(command);
const decoder = new TextDecoder();
const jsonString = decoder.decode(response.body);
const data = JSON.parse(jsonString);
console.log(data.content[0].text);
} catch (error) {
console.error("Error invoking model:", error);
}`,
    },

    vertexai: {
      python: `import os
import vertexai
from vertexai.generative_models import GenerativeModel

region = "us-central1"
vertexai.init(
  project="customer-prod-env",
  api_transport="rest",
  api_endpoint="https://${domain}",
  request_metadata=[
      ("ps-app-id", "${appId}"),
      ("forward-domain", f"{region}-aiplatform.googleapis.com"),
  ],
)
model = GenerativeModel("gemini-1.5-flash-002")
chat = model.start_chat()
response = chat.send_message("Hello")
print(response.text)`,
      typescript: `import { VertexAI } from "@google-cloud/vertexai";

const region = "us-central1";
const vertexAI = new VertexAI({
project: "customer-prod-env",
location: region,
apiEndpoint: "${domain}",
});

const generativeModel = vertexAI.getGenerativeModel(
{
  model: "gemini-1.5-flash-002",
},
{
  customHeaders: new Map([
    ["ps-app-id", "${appId}"],
    ["forward-domain", \`\${region}-aiplatform.googleapis.com\`],
  ]),
}
);

const resp = await generativeModel.generateContent("Hello");
const contentResponse = await resp.response;
console.log(contentResponse.candidates[0].content.parts[0].text);`,
    },

    langchain: {
      python: `import os
from langchain_openai import ChatOpenAI

model = ChatOpenAI(
  model="gpt-4o",
  base_url="https://${domain}/v1",
  default_headers={
      "ps-app-id": "${appId}"
  }
)
resp = model.invoke("Hello")
print(resp.content)`,
      typescript: `import { ChatOpenAI } from "@langchain/openai";

delete process.env.AZURE_OPENAI_API_KEY;

const model = new ChatOpenAI({
modelName: "gpt-4o",
configuration: {
  baseURL: "https://${domain}/v1",
  defaultHeaders: {
    "ps-app-id": "${appId}"
  },
},
});
const res = await model.invoke("Hello");
console.log(res.content);`,
    },

    ollama: {
      python: `import os
from ollama import Client

client = Client(
  host="https://${domain}/v1/ollama",
  headers={
      "ps-app-id": "${appId}",
      "forward-domain": "your-ollama-server-domain"
  }
)
response = client.chat(model='llama3.2', messages=[
{
  'role': 'user',
  'content': 'Hello',
},
])
print(response['message']['content'])`,
      typescript: `import { Ollama } from "ollama";

const ollama = new Ollama({
host: "https://${domain}/v1/ollama",
headers: {
  "ps-app-id": "${appId}",
  "forward-domain": "your-ollama-server-domain",
},
});

const response = await ollama.chat({
model: "llama3.1",
messages: [{ role: "user", content: "Hello" }],
});
console.log(response.message.content);`,
    },

    anthropic: {
      python: `import os
import anthropic

client = anthropic.Anthropic(
  base_url="https://${domain}",
  default_headers={
      "ps-app-id": "${appId}"
  }
)

message = client.messages.create(
  model="claude-3-5-sonnet-20240620",
  max_tokens=1000,
  temperature=0,
  messages=[
      {
          "role": "user",
          "content": [
              {
                  "type": "text",
                  "text": "Hello"
              }
          ]
      }
  ]
)
print(message.content[0].text)`,
      typescript: `import Anthropic from '@anthropic-ai/sdk';

const client = new Anthropic({
baseURL: "https://${domain}",
defaultHeaders: {
  "ps-app-id": "${appId}"
}
});

const message = await client.messages.create({
model: "claude-3-5-sonnet-20240620",
max_tokens: 1000,
temperature: 0,
messages: [
  {
    role: "user",
    content: [
      { type: "text", text: "Hello" }
    ]
  }
]
});
console.log(message.content[0].text);`,
    },

    huggingface: {
      python: `import os
from huggingface_hub import InferenceClient

client = InferenceClient(
  base_url="https://${domain}/v1/huggingface/models/HuggingFaceH4/zephyr-7b-beta",
  headers={
      "ps-app-id": "${appId}",
      "forward-domain": "api-inference.huggingface.co"
  }
)

output = client.text_generation("Hello")
print("\ntext_generation:\n", output)

output = client.chat_completion([{"role": "user", "content": "Hello"}])
print("\nchat_completion:\n", output.choices[0].message.content)`,
      typescript: `import { textGeneration, chatCompletion } from "@huggingface/inference";

const customFetch = (url, options) => {
  return fetch(url, {
      ...options,
      headers: {
          ...options.headers,
          "ps-app-id": "${appId}",
          "forward-domain": "api-inference.huggingface.co"
      }
  });
};

const text_generation = await textGeneration({
  accessToken: process.env.HF_TOKEN,
  endpointUrl: "https://${domain}/v1/huggingface/models/HuggingFaceH4/zephyr-7b-beta",
  inputs: "Hello"
  }, {fetch: customFetch});
console.log("\ntext_generation:\n", text_generation.generated_text);

const chat_completion = await chatCompletion({
  accessToken: process.env.HF_TOKEN,
  endpointUrl: "https://${domain}/v1/huggingface/models/mistralai/Mistral-7B-Instruct-v0.2",
  messages: [{ role: "user", content: "Hello" }]
}, {fetch: customFetch});
console.log("\nchat_completion:\n", chat_completion.choices[0].message.content);`,
    },

    perplexity: {
      python: `import os
from openai import OpenAI

client = OpenAI(
  api_key=os.getenv("PERPLEXITY_API_KEY"),
  base_url="https://${domain}/v1/",
  default_headers={
      "ps-app-id": "${appId}",
      "forward-domain": "api.perplexity.ai",
  },
)

response = client.chat.completions.create(
  model="llama-3.1-sonar-small-128k-online",
  messages=[
      {
          "role": "user",
          "content": ("Hello"),
      },
  ],
)
print(response)`,
      typescript: `import 'dotenv/config';
import OpenAI from 'openai';

const client = new OpenAI({
apiKey: process.env.PERPLEXITY_API_KEY,
baseURL: "https://${domain}/v1/",
defaultHeaders: {
  "ps-app-id": "${appId}",
  "forward-domain": "api.perplexity.ai"
}
});

const response = await client.chat.completions.create({
model: "llama-3.1-sonar-small-128k-online",
messages: [{ role: "user", content: "Hello" }]
});
console.log(response.choices[0].message.content);`,
    },

    gemini: {
      python: `import os
import google.generativeai as genai

genai.configure(
  api_key=os.getenv("GEMINI_API_KEY"),
  transport="rest",
  client_options={
      "api_endpoint": "https://${domain}",
  },
  default_metadata=[("ps-app-id", "${appId}")],
)
model = genai.GenerativeModel("gemini-1.5-flash")
response = model.generate_content("Hello")
print(response.text)
`,
      typescript: `import { GenerativeModel } from "@google/generative-ai";

const genai = new GenerativeModel(
process.env.GEMINI_API_KEY,
{
  model: "gemini-1.5-flash",
},
{
  baseUrl: "https://${domain}",
  customHeaders: {
    "ps-app-id": "${appId}",
  },
}
);

const ret = await genai.generateContent("Hello");
console.log(ret.response.text());`,
    },

    cohere: {
      python: `import os
import cohere
import httpx

co = cohere.Client(
  api_key=os.getenv("COHERE_API_KEY"),
  base_url="https://${domain}/v1/",
  httpx_client=httpx.Client(
      headers={
          "ps-app-id": "${appId}",
          "forward-domain": "api.cohere.ai",
      }
  ),
)

response = co.chat(model="command-r-08-2024", message="Hello")

print(response.text)`,
      typescript: `import { CohereClient } from "cohere-ai";

const cohere = new CohereClient({
token: process.env.COHERE_API_KEY,
environment: "https://${domain}"
});
const response = await cohere.chat({
model: "command-r-08-2024",
message: "Hello"
}, {
headers: {
    "ps-app-id": "${appId}",
    "forward-domain": "api.cohere.ai",
}
});
console.log(response.text);`,
    },

    groq: {
      python: `import os
from groq import Groq

client = Groq(
  api_key=os.environ.get("GROQ_API_KEY"),
  base_url="https://${domain}",
  default_headers={
      "ps-app-id": "${appId}",
      "forward-domain": "api.groq.com",
  },
)

chat_completion = client.chat.completions.create(
  messages=[
      {
          "role": "user",
          "content": "Hello",
      }
  ],
  model="llama3-8b-8192",
)
print(chat_completion.choices[0].message.content)`,
      typescript: `import Groq from 'groq-sdk';

const client = new Groq({
baseURL: "https://${domain}",
defaultHeaders: {
  "ps-app-id": "${appId}",
  "forward-domain": "api.groq.com",
}
});

const chatCompletion = await client.chat.completions.create({
messages: [{ role: 'user', content: 'Hello' }],
model: 'llama3-8b-8192',
});

console.log(chatCompletion.choices[0].message.content);`,
    },

    xai: {
      python: `import os
from openai import OpenAI

client = OpenAI(
  api_key=os.getenv("XAI_API_KEY"),
  base_url="https://${domain}/v1",
  default_headers={
      "ps-app-id": "${appId}",
      "forward-domain": "api.x.ai",
  },
)

completion = client.chat.completions.create(
  model="grok-beta",
  messages=[
      {
          "role": "user",
          "content": "Hello",
      },
  ],
)

print(completion.choices[0].message.content)`,
      typescript: `import OpenAI from 'openai';

const client = new OpenAI({
apiKey: process.env.XAI_API_KEY,
baseURL: "https://${domain}/v1",
defaultHeaders: {
  "ps-app-id": "${appId}",
  "forward-domain": "api.x.ai"
}
});

const completion = await client.chat.completions.create({
model: "grok-beta",
messages: [
  { role: "user", content: "Hello" }
]
});
console.log(completion.choices[0].message.content);`,
    },

    mistral: {
      python: `import os
from mistralai import Mistral

client = Mistral(
  api_key=os.environ["MISTRAL_API_KEY"], server_url="https://${domain}"
)

chat_response = client.chat.complete(
  model="mistral-small-latest",
  messages=[
      {
          "role": "user",
          "content": "Hello",
      },
  ],
  http_headers={
      "ps-app-id": "${appId}",
      "forward-domain": "api.mistral.ai",
  },
)

print(chat_response.choices[0].message.content)`,
      typescript: `import MistralClient from "@mistralai/mistralai";

const client = new MistralClient.Mistral({
apiKey: process.env.MISTRAL_API_KEY,
serverURL: "https://${domain}",
});

const chatResponse = await client.chat.complete(
{
  model: "mistral-tiny",
  messages: [{ role: "user", content: "Hello" }],
},
{
  fetchOptions: {
    headers: {
      "ps-app-id": "${appId}",
      "forward-domain": "api.mistral.ai",
    },
  },
}
);

console.log(chatResponse.choices[0].message.content);`,
    },

    nvidia: {
      python: `import os
from openai import OpenAI

client = OpenAI(
  base_url="https://${domain}/v1",
  api_key=os.getenv("NVIDIA_API_KEY"),
  default_headers={
      "ps-app-id": "${appId}",
      "forward-domain": "integrate.api.nvidia.com",
  },
)

completion = client.chat.completions.create(
  model="mistralai/mixtral-8x7b-instruct-v0.1",
  messages=[{"role": "user", "content": "Hello"}],
)
print(completion)`,
      typescript: `import OpenAI from 'openai';

const client = new OpenAI({
baseURL: "https://${domain}/v1",
apiKey: process.env.NVIDIA_API_KEY,
defaultHeaders: {
  "ps-app-id": "${appId}",
  "forward-domain": "integrate.api.nvidia.com"
}
});

const completion = await client.chat.completions.create({
model: "mistralai/mixtral-8x7b-instruct-v0.1",
messages: [{ role: "user", content: "Hello" }]
});
console.log(completion);`,
    },

    ai21: {
      python: `import os
from ai21 import AI21Client
from ai21.models.chat import ChatMessage

client = AI21Client(
  api_key=os.environ.get("AI21_API_KEY"),
  api_host="https://${domain}/v1",
  headers={
      "ps-app-id": "${appId}",
      "forward-domain": "api.ai21.com"
  }
)
resp = client.chat.completions.create(
  model="jamba-1.5-large",
  messages=[ChatMessage(role="user", content="Hello")],
)
print(resp.choices[0].message.content)`,
      typescript: `import { AI21 } from "ai21";

const client = new AI21({
apiKey: process.env.AI21_API_KEY,
baseURL: "https://${domain}/v1"
});

const response = await client.chat.completions.create(
{
  model: "jamba-1.5-mini",
  messages: [{ role: "user", content: "Hello" }],
},
{
  headers: {
    "ps-app-id": "${appId}",
    "forward-domain": "api.ai21.com",
  }
}
);

console.log(response.choices[0].message.content);`,
    },

    fireworks: {
      python: `import os
from openai import OpenAI

client = OpenAI(
  api_key=os.getenv("FIREWORKS_API_KEY"),
  base_url="https://${domain}/v1/",
  default_headers={"ps-app-id": "${appId}"},
)

response = client.chat.completions.create(
  model="accounts/fireworks/models/llama-v3p2-3b-instruct",
  messages=[
      {"role": "user", "content": "Hello"},
  ],
)
print(response.choices[0].message.content.strip())`,
      typescript: `import OpenAI from "openai";

const client = new OpenAI({
apiKey: process.env.FIREWORKS_API_KEY,
baseURL: "https://${domain}/v1/",
defaultHeaders: { "ps-app-id": "${appId}" },
});

const response = await client.chat.completions.create({
model: "accounts/fireworks/models/llama-v3p2-3b-instruct",
messages: [{ role: "user", content: "Hello" }],
});
console.log(response.choices[0].message.content.trim());`,
    },

    openrouter: {
      python: `import os
from openai import OpenAI

client = OpenAI(
  base_url="https://${domain}/v1",
  api_key=os.getenv("OPENROUTER_API_KEY"),
  default_headers={"ps-app-id": "${appId}"},
)

completion = client.chat.completions.create(
  model="openai/gpt-3.5-turbo", 
  messages=[{"role": "user", "content": "Hello"}]
)
print(completion.choices[0].message.content)`,
      typescript: `import OpenAI from "openai";

const client = new OpenAI({
baseURL: "https://${domain}/v1",
apiKey: process.env.OPENROUTER_API_KEY,
defaultHeaders: { "ps-app-id": "${appId}" },
});

const completion = await client.chat.completions.create({
model: "openai/gpt-3.5-turbo",
messages: [{ role: "user", content: "Hello" }],
});
console.log(completion.choices[0].message.content);`,
    },

    replicate: {
      python: `import os
import replicate

client = replicate.Client(
  base_url="https://${domain}",
  headers={"ps-app-id": "${appId}"}
)
for event in client.run(
  "mistralai/mistral-7b-v0.1",
  input={
      "prompt": "Hello",
  }
):
  print(event, end="")`,
      typescript: `import Replicate from "replicate";

const replicate = new Replicate({
  baseUrl: "https://${domain}/v1/",
  fetch: (input, init = {}) => {
      return fetch(input, {
          ...init,
          headers: {
              ...init.headers,
              "ps-app-id": "${appId}"
          }
      });
  }
});
let response = await replicate.run("mistralai/mistral-7b-v0.1", {
  input: {
      prompt: "Hello"
  },
});
for (const event of response) {
  console.log(event);
}`,
    },

    together: {
      python: `import os
from together import Together

client = Together(
  base_url="https://${domain}/v1",
  supplied_headers={
      "ps-app-id": "${appId}",
      "forward-domain": "api.together.ai",
  },
)

response = client.chat.completions.create(
  model="meta-llama/Llama-3.3-70B-Instruct-Turbo",
  messages=[{"role": "user", "content": "Hello"}],
)
print(response.choices[0].message.content)`,
      typescript: `import Together from 'together-ai';

const client = new Together({
  baseURL: "https://${domain}/v1",
  defaultHeaders: {
      "ps-app-id": "${appId}",
      "forward-domain": "api.together.ai"
  }
});

const chatCompletion = await client.chat.completions.create({
messages: [{ role: 'user', content: 'Hello' }],
model: 'mistralai/Mixtral-8x7B-Instruct-v0.1',
});

console.log(chatCompletion.choices[0].message.content);`,
    },
  };
};
