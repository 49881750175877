/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

export const ProviderButtonStyle = {
    self: css`
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        width: 156px;
        height: 72px;
        padding: 25px;
        border: 1px solid var(--color-black-40);
        border-radius: 15px;
        user-select: none;
        background-color: var(--color-white);
        text-align: left;

        &:hover, &:focus-visible {
            border-color: var(--color-blue-30);
            border-width: 2px;
        }
        &:active, &.selected {
            border-color: var(--color-black);
            border-width: 2px;
        }
        &.selected {
            box-shadow: var(--outer-elevation-1);
            & .text { font-weight: 700; }
        }
    `
}