/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

export const HomegrownApplicationsReverseProxyStyle = {
    self: css`
    `,
    title: css`
        border-bottom: 1px solid var(--color-black-30);
        padding-bottom: 20px;
        margin-bottom: 20px;
    `,
    proxyMappingContainer: css`
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin: 20px 0;
    `,
    proxyMappingRow: css`
        display: flex;
        align-items: center;
        gap: 10px;
    `,
    actionsContainer: css`
        display: flex;
        gap: 10px;
    `,
    librariesContainer: css`
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-top: 20px;
    `,
    codeViewerContainer: css`
        position: relative;
    `,
    copyButton: css`
        position: absolute;
        right: 10px;
        top: 10px;
    `,
    noProxyMapping: css`
        margin-top: 20px;
    `
}